.project_container {
  background-color: var(--main_orange_color);
  padding-bottom: 20px;
}

.project_item {
  background-color: var(--main_blue_color);
  color: var(--main_offWhite_color);
  border-radius: 15px;
  margin: 20px 5%;
  padding: 1rem;
  height: fit-content;
}

.page_title {
  margin-top: 2rem;
  padding-top: 1rem;
  font-size: 1rem;
  text-align: center;
}

.project_name h2 {
  margin-top: 5px;
  font-size: 1rem;
  color: var(--main_orange_color);
  text-align: center;
}

.project_description h5 {
  margin-top: 5px;
  font-size: 0.8rem;
  text-align: center;
}

.project_details h5 {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main_orange_color);
}

.project_li_title {
  font-size: 0.9rem;
  margin-top: 10px;
  font-weight: bold;
}
.project_details li {
  margin-left: 30px;
}

.project_details ul {
  font-size: 0.8rem;
  list-style-type: circle;
}

.project_picture {
  margin-top: 10px;
}

.rescuePic {
  width: 300px;
  height: auto;
}
.galileoPic {
  width: 300px;
  height: auto;
}

.billPayPic {
  width: 300px;
  height: auto;
}
.billPayPortalPic {
  width: auto;
  height: 300px;
}
.projectManagerPic {
  width: auto;
  height: 300px;
}

.EcPic {
  height: auto;
  width: 500px;
}

.flexForPic {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1rem;
}
.flexForPic_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.flexForPic_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 800px) {
  .project_item {
    padding: 2rem;
  }

  .page_title {
    font-size: 1.2rem;
  }

  .project_name h2 {
    margin-top: 5px;
    font-size: 1.2rem;
  }

  .project_description h5 {
    font-size: 1rem;
  }

  .project_details h5 {
    font-size: 1.2rem;
  }

  .project_li_title {
    font-size: 1rem;
  }

  .project_details ul {
    font-size: 1rem;
  }
  .flexForPic {
    display: flex;
    flex-direction: row;
    max-width: 600px;
  }
  .flexForPic_right {
    justify-content: flex-start;
    align-items: center;
    min-width: 300px;
    padding-top: 2rem;
  }
  .flexForPic_left {
    min-width: 300px;
  }

  .project_picture {
    margin-left: 10px;
  }

  .rescuePic {
    width: 300px;
    height: auto;
  }
  .galileoPic {
    width: 350px;
    height: auto;
  }
}

@media screen and (min-width: 1000px) {
  .project_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page_title {
    font-size: 1.3rem;
  }

  .project_name h2 {
    font-size: 1.3rem;
  }

  .project_description h5 {
    font-size: 1.1rem;
  }

  .project_details h5 {
    font-size: 1.3rem;
  }

  .project_li_title {
    font-size: 1.1rem;
  }

  .project_details ul {
    font-size: 1.1rem;
  }
  .project_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 800px;
  }
  .flexForPic {
    width: 800px;
    max-width: 800px;
  }
  .flexForPic_right {
    width: 400px;
    padding-top: 2rem;
  }
  .flexForPic_left {
    width: 400px;
  }

  .billPayPortalPic {
    height: 400px;
  }

  .projectManagerPic {
    height: auto;
    width: 500px;
  }
  .EcPic {
    height: auto;
    width: 400px;
  }
}

@media screen and (min-width: 1400px) {
  .project_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 1000px;
  }
  .flexForPic {
    width: 1000px;
    max-width: 1000px;
  }
  .flexForPic_right {
    width: 500px;
    padding-top: 2rem;
  }
  .flexForPic_left {
    width: 500px;
  }

  .rescuePic {
    width: 400px;
  }
  .galileoPic {
    width: 400px;
    height: auto;
    margin-left: 50px;
  }
  .billPayPic {
    width: 300px;
    height: auto;
    margin-left: 80px;
  }
}
@media screen and (min-width: 1800px) {
  .project_item {
    padding: 2.5rem;
  }

  .page_title {
    font-size: 1.6rem;
  }

  .project_name h2 {
    font-size: 2.5rem;
  }

  .project_description h5 {
    font-size: 1.5rem;
  }

  .project_details h5 {
    font-size: 1.4rem;
  }

  .project_li_title {
    font-size: 2rem;
  }

  .project_details ul {
    font-size: 1.5rem;
  }
  .rescuePic {
    width: 600px;
    margin-left: 25px;
  }
  .galileoPic {
    width: 700px;
    height: auto;
    margin-left: 50px;
  }
}
