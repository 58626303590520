.Adv_container {
  position: relative;
}
.Advantages_container1 {
  position: relative;
  width: 100%;
  height: 300px;
}

.Background_color_container1 {
  background: var(--main_orange_color);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.Advantages_container1 ul {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  top: 10%;
  left: 10%;
  list-style: none;
}

.Advantages_container1 li {
  color: var(--main_offWhite_color);
  text-align: left;
}
.Advantages_container1 li:nth-child(2),
.Advantages_container1 li:nth-child(3) {
  margin-top: 18px;
}

.Advantages_container1 h2 {
  color: var(--main_blue_color);
  text-align: left;
}
.Advantages_container1 p {
  color: var(--main_offWhite_color);
  text-align: left;
  font-size: 12px;
  padding-right: 25%;
}

.Advantages_container2 {
  position: relative;
}
.Advantages_container2 ul {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3;
  top: 10%;
  right: 10%;
  list-style: none;
}
.Advantages_container2 li {
  color: var(--main_offWhite_color);
  text-align: right;
}
.Advantages_container2 h2 {
  color: var(--main_orange_color);
  text-align: right;
}
.Advantages_container2 p {
  color: var(--main_offWhite_color);
  text-align: right;
  font-size: 12px;
  padding-left: 40%;
}

.Background_image {
  position: relative;
}

.Background_image img {
  z-index: 1;
  width: 100%;
}

.Advantages_Foreground_color {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #00457ac7;
  top: 0;
  left: 0;
}

@media screen and (min-width: 500px) {
  .Advantages_container1 li:nth-child(2),
  .Advantages_container1 li:nth-child(3) {
    margin-top: 18px;
  }

  .Advantages_container1 h2 {
    font-size: 30px;
  }
  .Advantages_container1 p {
    font-size: 15px;
    padding-right: 25%;
  }

  .Advantages_container2 li {
    margin-top: 15px;
  }
  .Advantages_container2 h2 {
    font-size: 30px;
  }
  .Advantages_container2 p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .Advantages_container1 {
    min-height: 500px;
  }

  .Advantages_container1 li:nth-child(2),
  .Advantages_container1 li:nth-child(3) {
    margin-top: 18px;
  }

  .Advantages_container1 h2 {
    font-size: 50px;
  }
  .Advantages_container1 p {
    font-size: 25px;
    padding-right: 25%;
  }

  .Advantages_container2 li {
    margin-top: 15px;
  }
  .Advantages_container2 h2 {
    font-size: 50px;
  }
  .Advantages_container2 p {
    font-size: 25px;
  }
}
