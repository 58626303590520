.Hero_section {
  position: relative;
}

.Hero_section img {
  width: 100%;
  z-index: 1;
}
.Blur_foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00457a77;
  z-index: 2;
  top: 0;
  left: 0;
}

.Hero_section h1 {
  color: var(--main_offWhite_color);
  font-size: 25px;
  width: 70%;
  text-align: center;
  font-weight: bold;
  position: absolute;
  z-index: 3;
  bottom: 10%;
  left: 15%;
}

@media screen and (min-width: 500px) {
  .Hero_section h1 {
    font-size: 30px;
    width: 65%;
    bottom: 10%;
    left: 17%;
  }
}

@media screen and (min-width: 700px) {
  .Hero_section h1 {
    font-size: 40px;
    width: 50%;
    bottom: 10%;
    left: 25%;
  }
}

@media screen and (min-width: 1000px) {
  .Hero_section h1 {
    font-size: 60px;
    text-align: right;
    width: 60%;
    bottom: 35%;
    left: 35%;
  }
}

@media screen and (min-width: 1400px) {
  .Hero_section h1 {
    font-size: 85px;
    text-align: right;
    width: 60%;
    bottom: 35%;
    left: 35%;
  }

  .Hero_section img {
    width: 100%;
    z-index: 1;
  }
  .Blur_foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00457a77;
    z-index: 2;
    top: 0;
    left: 0;
  }
}
@media screen and (min-width: 1920px) {
  .Hero_section h1 {
    font-size: 100px;
    text-align: right;
    width: 50%;
    bottom: 20%;
    left: 45%;
  }
}
