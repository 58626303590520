.footer_Nav_container {
  background-color: var(--main_offWhite_color);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* margin-top: 20px; */
}

.footer_copyright {
  font-size: 12px;
  font-weight: bold;
  color: var(--main_blue_color);
}

.social_icons {
  text-align: right;
}
.social_icons img {
  width: 20px;
  text-align: right;
  margin-right: 16px;
}

@media screen and (min-width: 500px) {
  .footer_copyright {
    font-size: 16px;
    margin-top: 5px;
  }

  .social_icons img {
    width: 35px;
  }
}

@media screen and (min-width: 1000px) {
  .footer_copyright {
    font-size: 18px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .social_icons img {
    width: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .footer_copyright {
    font-size: 20px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .social_icons img {
    width: 45px;
  }
}
