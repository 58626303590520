/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Poppins:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  --main_blue_color: #00457a;
  --main_offWhite_color: #f3f3f3;
  --main_orange_color: #f38508;
  /* --main_font_family: "Poppins", sans-serif; */
  --main_font_family: "Noto Sans", sans-serif;
}

.App {
  background: var(--main_blue_color);
  height: 100vh;
  font-family: var(--main_font_family);
}

.text-bold {
  font-weight: bold;
}
