.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--main_blue_color);
}

.contact_alert {
  background-color: var(--main_orange_color);
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 5px;
  font-weight: bold;
}

.contact_headline {
  font-weight: bold;
  color: var(--main_orange_color);
  margin-top: 10%;
}

.contactForm {
  width: 50%;
  margin-top: 10%;
}

.contactButton {
  font-weight: bold;
  font-size: 1rem;
  background: var(--main_orange_color);
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0px 0px 10px;
  cursor: pointer;
}
.contactButton:hover {
  background-color: #cf6f00;
  color: var(--main_blue_color);
}

.form-control {
  width: 100%;
  margin: 5px 0px;
  padding: 10px;
}

label {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--main_orange_color);
}

input {
  border-radius: 5px;
  background: #2c73aa;
  font-size: 1rem;
  color: aliceblue;
}

textarea {
  border-radius: 5px;
  background: #2c73aa;
  font-size: 1rem;
  color: aliceblue;
}

.contactInformation {
  margin-top: 10%;
  margin-bottom: 10%;
}

ul {
  list-style-type: none;
  color: var(--main_orange_color);
}

.ContactInformationHeadline {
  font-weight: bold;
  text-align: center;
  font-size: 150%;
}

@media screen and (min-width: 1500px) {
  .contact_headline {
    margin-top: 5%;
  }

  .contactForm {
    margin-top: 5%;
    max-width: 40%;
  }

  .contactInformation {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
