@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Poppins:wght@400;700&display=swap);
.Nav_container {
  background-color: var(--main_offWhite_color);
}

.Desktop_menu {
  display: none;
}

nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  margin: 2% 5%;
  width: 35%;
}

.Hamburger_menu {
  margin-right: 5%;
}

.Hamburger_svg-icon {
  width: 2em;
  height: 2em;
}

.Hamburger_svg-icon path,
.Hamburger_svg-icon polygon,
.Hamburger_svg-icon rect {
  fill: var(--main_blue_color);
}

.Hamburger_svg-icon circle {
  stroke: var(--main_blue_color);
  stroke-width: 1;
}

.Mobile_menu {
  background: var(--main_orange_color);
  margin-left: 55%;
  padding-top: 2%;
  padding-right: 10%;
  padding-bottom: 2%;
  border-radius: 5px;
}
.Mobile_menu ul {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: right;
          justify-content: right;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.Mobile_menu li {
  margin-top: 30px;
}
.Mobile_menu a {
  text-decoration: none;
  color: var(--main_blue_color);
  font-weight: bold;
  font-size: 25px;
}
.Mobile_menu a:hover {
  /* color: var(--main_offWhite_color); */
  color: white;
}

@media screen and (min-width: 500px) {
  .Hamburger_svg-icon {
    width: 3.5em;
    height: 3.5em;
  }

  .Mobile_menu a {
    font-size: 40px;
  }

  .Mobile_menu {
    margin-left: 45%;
  }
}

@media screen and (min-width: 700px) {
  .logo {
    width: 25%;
  }
}

@media screen and (min-width: 1000px) {
  .Desktop_menu {
    display: block;
    margin-right: 5%;
  }
  .Desktop_menu ul {
    list-style: none;
    display: -webkit-flex;
    display: flex;
  }
  .Desktop_menu li {
    margin: 0 15px;
  }

  .Desktop_menu a {
    text-decoration: none;
    color: var(--main_blue_color);
    font-weight: bold;
    font-size: 20px;
  }

  .Desktop_menu a:hover {
    color: var(--main_orange_color);
  }

  .Hamburger_menu {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .logo {
    width: 15%;
  }

  .Desktop_menu a {
    font-size: 25px;
  }
}

@media screen and (min-width: 1920px) {
  .logo {
    width: 10%;
  }

  .Desktop_menu a {
    font-size: 35px;
  }
}

.footer_Nav_container {
  background-color: var(--main_offWhite_color);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px;
  /* margin-top: 20px; */
}

.footer_copyright {
  font-size: 12px;
  font-weight: bold;
  color: var(--main_blue_color);
}

.social_icons {
  text-align: right;
}
.social_icons img {
  width: 20px;
  text-align: right;
  margin-right: 16px;
}

@media screen and (min-width: 500px) {
  .footer_copyright {
    font-size: 16px;
    margin-top: 5px;
  }

  .social_icons img {
    width: 35px;
  }
}

@media screen and (min-width: 1000px) {
  .footer_copyright {
    font-size: 18px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .social_icons img {
    width: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .footer_copyright {
    font-size: 20px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .social_icons img {
    width: 45px;
  }
}

.Hero_section {
  position: relative;
}

.Hero_section img {
  width: 100%;
  z-index: 1;
}
.Blur_foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00457a77;
  z-index: 2;
  top: 0;
  left: 0;
}

.Hero_section h1 {
  color: var(--main_offWhite_color);
  font-size: 25px;
  width: 70%;
  text-align: center;
  font-weight: bold;
  position: absolute;
  z-index: 3;
  bottom: 10%;
  left: 15%;
}

@media screen and (min-width: 500px) {
  .Hero_section h1 {
    font-size: 30px;
    width: 65%;
    bottom: 10%;
    left: 17%;
  }
}

@media screen and (min-width: 700px) {
  .Hero_section h1 {
    font-size: 40px;
    width: 50%;
    bottom: 10%;
    left: 25%;
  }
}

@media screen and (min-width: 1000px) {
  .Hero_section h1 {
    font-size: 60px;
    text-align: right;
    width: 60%;
    bottom: 35%;
    left: 35%;
  }
}

@media screen and (min-width: 1400px) {
  .Hero_section h1 {
    font-size: 85px;
    text-align: right;
    width: 60%;
    bottom: 35%;
    left: 35%;
  }

  .Hero_section img {
    width: 100%;
    z-index: 1;
  }
  .Blur_foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00457a77;
    z-index: 2;
    top: 0;
    left: 0;
  }
}
@media screen and (min-width: 1920px) {
  .Hero_section h1 {
    font-size: 100px;
    text-align: right;
    width: 50%;
    bottom: 20%;
    left: 45%;
  }
}

.Adv_container {
  position: relative;
}
.Advantages_container1 {
  position: relative;
  width: 100%;
  height: 300px;
}

.Background_color_container1 {
  background: var(--main_orange_color);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.Advantages_container1 ul {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 3;
  top: 10%;
  left: 10%;
  list-style: none;
}

.Advantages_container1 li {
  color: var(--main_offWhite_color);
  text-align: left;
}
.Advantages_container1 li:nth-child(2),
.Advantages_container1 li:nth-child(3) {
  margin-top: 18px;
}

.Advantages_container1 h2 {
  color: var(--main_blue_color);
  text-align: left;
}
.Advantages_container1 p {
  color: var(--main_offWhite_color);
  text-align: left;
  font-size: 12px;
  padding-right: 25%;
}

.Advantages_container2 {
  position: relative;
}
.Advantages_container2 ul {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  z-index: 3;
  top: 10%;
  right: 10%;
  list-style: none;
}
.Advantages_container2 li {
  color: var(--main_offWhite_color);
  text-align: right;
}
.Advantages_container2 h2 {
  color: var(--main_orange_color);
  text-align: right;
}
.Advantages_container2 p {
  color: var(--main_offWhite_color);
  text-align: right;
  font-size: 12px;
  padding-left: 40%;
}

.Background_image {
  position: relative;
}

.Background_image img {
  z-index: 1;
  width: 100%;
}

.Advantages_Foreground_color {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #00457ac7;
  top: 0;
  left: 0;
}

@media screen and (min-width: 500px) {
  .Advantages_container1 li:nth-child(2),
  .Advantages_container1 li:nth-child(3) {
    margin-top: 18px;
  }

  .Advantages_container1 h2 {
    font-size: 30px;
  }
  .Advantages_container1 p {
    font-size: 15px;
    padding-right: 25%;
  }

  .Advantages_container2 li {
    margin-top: 15px;
  }
  .Advantages_container2 h2 {
    font-size: 30px;
  }
  .Advantages_container2 p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .Advantages_container1 {
    min-height: 500px;
  }

  .Advantages_container1 li:nth-child(2),
  .Advantages_container1 li:nth-child(3) {
    margin-top: 18px;
  }

  .Advantages_container1 h2 {
    font-size: 50px;
  }
  .Advantages_container1 p {
    font-size: 25px;
    padding-right: 25%;
  }

  .Advantages_container2 li {
    margin-top: 15px;
  }
  .Advantages_container2 h2 {
    font-size: 50px;
  }
  .Advantages_container2 p {
    font-size: 25px;
  }
}

.services_container {
  background-color: var(--main_blue_color);
  height: 200vh;
}

.services_page_title {
  color: var(--main_offWhite_color);
  margin-top: 2rem;
  padding-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.services_available {
  background-color: var(--main_orange_color);
  color: var(--main_offWhite_color);
  border-radius: 15px;
  margin: 20px 5%;
  padding: 1rem;
  height: -webkit-fit-content;
  height: fit-content;
}

.service_item {
  margin-top: 5px;
  font-size: 0.9rem;
  text-align: center;
  color: var(--main_blue_color);
}

@media screen and (min-width: 800px) {
  .services_page_title {
    font-size: 1.2rem;
    text-align: center;
  }

  .services_flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .services_available {
    min-width: 325px;
  }

  .service_item {
    font-size: 1.25rem;
  }
}

.project_container {
  background-color: var(--main_orange_color);
  padding-bottom: 20px;
}

.project_item {
  background-color: var(--main_blue_color);
  color: var(--main_offWhite_color);
  border-radius: 15px;
  margin: 20px 5%;
  padding: 1rem;
  height: -webkit-fit-content;
  height: fit-content;
}

.page_title {
  margin-top: 2rem;
  padding-top: 1rem;
  font-size: 1rem;
  text-align: center;
}

.project_name h2 {
  margin-top: 5px;
  font-size: 1rem;
  color: var(--main_orange_color);
  text-align: center;
}

.project_description h5 {
  margin-top: 5px;
  font-size: 0.8rem;
  text-align: center;
}

.project_details h5 {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main_orange_color);
}

.project_li_title {
  font-size: 0.9rem;
  margin-top: 10px;
  font-weight: bold;
}
.project_details li {
  margin-left: 30px;
}

.project_details ul {
  font-size: 0.8rem;
  list-style-type: circle;
}

.project_picture {
  margin-top: 10px;
}

.rescuePic {
  width: 300px;
  height: auto;
}
.galileoPic {
  width: 300px;
  height: auto;
}

.billPayPic {
  width: 300px;
  height: auto;
}
.billPayPortalPic {
  width: auto;
  height: 300px;
}
.projectManagerPic {
  width: auto;
  height: 300px;
}

.EcPic {
  height: auto;
  width: 500px;
}

.flexForPic {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  margin-top: 1rem;
}
.flexForPic_left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem;
}
.flexForPic_right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (min-width: 800px) {
  .project_item {
    padding: 2rem;
  }

  .page_title {
    font-size: 1.2rem;
  }

  .project_name h2 {
    margin-top: 5px;
    font-size: 1.2rem;
  }

  .project_description h5 {
    font-size: 1rem;
  }

  .project_details h5 {
    font-size: 1.2rem;
  }

  .project_li_title {
    font-size: 1rem;
  }

  .project_details ul {
    font-size: 1rem;
  }
  .flexForPic {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    max-width: 600px;
  }
  .flexForPic_right {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    min-width: 300px;
    padding-top: 2rem;
  }
  .flexForPic_left {
    min-width: 300px;
  }

  .project_picture {
    margin-left: 10px;
  }

  .rescuePic {
    width: 300px;
    height: auto;
  }
  .galileoPic {
    width: 350px;
    height: auto;
  }
}

@media screen and (min-width: 1000px) {
  .project_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  .page_title {
    font-size: 1.3rem;
  }

  .project_name h2 {
    font-size: 1.3rem;
  }

  .project_description h5 {
    font-size: 1.1rem;
  }

  .project_details h5 {
    font-size: 1.3rem;
  }

  .project_li_title {
    font-size: 1.1rem;
  }

  .project_details ul {
    font-size: 1.1rem;
  }
  .project_item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    width: 800px;
  }
  .flexForPic {
    width: 800px;
    max-width: 800px;
  }
  .flexForPic_right {
    width: 400px;
    padding-top: 2rem;
  }
  .flexForPic_left {
    width: 400px;
  }

  .billPayPortalPic {
    height: 400px;
  }

  .projectManagerPic {
    height: auto;
    width: 500px;
  }
  .EcPic {
    height: auto;
    width: 400px;
  }
}

@media screen and (min-width: 1400px) {
  .project_item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 2rem;
    width: 1000px;
  }
  .flexForPic {
    width: 1000px;
    max-width: 1000px;
  }
  .flexForPic_right {
    width: 500px;
    padding-top: 2rem;
  }
  .flexForPic_left {
    width: 500px;
  }

  .rescuePic {
    width: 400px;
  }
  .galileoPic {
    width: 400px;
    height: auto;
    margin-left: 50px;
  }
  .billPayPic {
    width: 300px;
    height: auto;
    margin-left: 80px;
  }
}
@media screen and (min-width: 1800px) {
  .project_item {
    padding: 2.5rem;
  }

  .page_title {
    font-size: 1.6rem;
  }

  .project_name h2 {
    font-size: 2.5rem;
  }

  .project_description h5 {
    font-size: 1.5rem;
  }

  .project_details h5 {
    font-size: 1.4rem;
  }

  .project_li_title {
    font-size: 2rem;
  }

  .project_details ul {
    font-size: 1.5rem;
  }
  .rescuePic {
    width: 600px;
    margin-left: 25px;
  }
  .galileoPic {
    width: 700px;
    height: auto;
    margin-left: 50px;
  }
}

.contactContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: var(--main_blue_color);
}

.contact_alert {
  background-color: var(--main_orange_color);
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 5px;
  font-weight: bold;
}

.contact_headline {
  font-weight: bold;
  color: var(--main_orange_color);
  margin-top: 10%;
}

.contactForm {
  width: 50%;
  margin-top: 10%;
}

.contactButton {
  font-weight: bold;
  font-size: 1rem;
  background: var(--main_orange_color);
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0px 0px 10px;
  cursor: pointer;
}
.contactButton:hover {
  background-color: #cf6f00;
  color: var(--main_blue_color);
}

.form-control {
  width: 100%;
  margin: 5px 0px;
  padding: 10px;
}

label {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--main_orange_color);
}

input {
  border-radius: 5px;
  background: #2c73aa;
  font-size: 1rem;
  color: aliceblue;
}

textarea {
  border-radius: 5px;
  background: #2c73aa;
  font-size: 1rem;
  color: aliceblue;
}

.contactInformation {
  margin-top: 10%;
  margin-bottom: 10%;
}

ul {
  list-style-type: none;
  color: var(--main_orange_color);
}

.ContactInformationHeadline {
  font-weight: bold;
  text-align: center;
  font-size: 150%;
}

@media screen and (min-width: 1500px) {
  .contact_headline {
    margin-top: 5%;
  }

  .contactForm {
    margin-top: 5%;
    max-width: 40%;
  }

  .contactInformation {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap"); */

* {
  margin: 0;
  padding: 0;
}

:root {
  --main_blue_color: #00457a;
  --main_offWhite_color: #f3f3f3;
  --main_orange_color: #f38508;
  /* --main_font_family: "Poppins", sans-serif; */
  --main_font_family: "Noto Sans", sans-serif;
}

.App {
  background: #00457a;
  background: var(--main_blue_color);
  height: 100vh;
  font-family: "Noto Sans", sans-serif;
  font-family: var(--main_font_family);
}

.text-bold {
  font-weight: bold;
}

