.Nav_container {
  background-color: var(--main_offWhite_color);
}

.Desktop_menu {
  display: none;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin: 2% 5%;
  width: 35%;
}

.Hamburger_menu {
  margin-right: 5%;
}

.Hamburger_svg-icon {
  width: 2em;
  height: 2em;
}

.Hamburger_svg-icon path,
.Hamburger_svg-icon polygon,
.Hamburger_svg-icon rect {
  fill: var(--main_blue_color);
}

.Hamburger_svg-icon circle {
  stroke: var(--main_blue_color);
  stroke-width: 1;
}

.Mobile_menu {
  background: var(--main_orange_color);
  margin-left: 55%;
  padding-top: 2%;
  padding-right: 10%;
  padding-bottom: 2%;
  border-radius: 5px;
}
.Mobile_menu ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
}
.Mobile_menu li {
  margin-top: 30px;
}
.Mobile_menu a {
  text-decoration: none;
  color: var(--main_blue_color);
  font-weight: bold;
  font-size: 25px;
}
.Mobile_menu a:hover {
  /* color: var(--main_offWhite_color); */
  color: white;
}

@media screen and (min-width: 500px) {
  .Hamburger_svg-icon {
    width: 3.5em;
    height: 3.5em;
  }

  .Mobile_menu a {
    font-size: 40px;
  }

  .Mobile_menu {
    margin-left: 45%;
  }
}

@media screen and (min-width: 700px) {
  .logo {
    width: 25%;
  }
}

@media screen and (min-width: 1000px) {
  .Desktop_menu {
    display: block;
    margin-right: 5%;
  }
  .Desktop_menu ul {
    list-style: none;
    display: flex;
  }
  .Desktop_menu li {
    margin: 0 15px;
  }

  .Desktop_menu a {
    text-decoration: none;
    color: var(--main_blue_color);
    font-weight: bold;
    font-size: 20px;
  }

  .Desktop_menu a:hover {
    color: var(--main_orange_color);
  }

  .Hamburger_menu {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .logo {
    width: 15%;
  }

  .Desktop_menu a {
    font-size: 25px;
  }
}

@media screen and (min-width: 1920px) {
  .logo {
    width: 10%;
  }

  .Desktop_menu a {
    font-size: 35px;
  }
}
