.services_container {
  background-color: var(--main_blue_color);
  height: 200vh;
}

.services_page_title {
  color: var(--main_offWhite_color);
  margin-top: 2rem;
  padding-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.services_available {
  background-color: var(--main_orange_color);
  color: var(--main_offWhite_color);
  border-radius: 15px;
  margin: 20px 5%;
  padding: 1rem;
  height: fit-content;
}

.service_item {
  margin-top: 5px;
  font-size: 0.9rem;
  text-align: center;
  color: var(--main_blue_color);
}

@media screen and (min-width: 800px) {
  .services_page_title {
    font-size: 1.2rem;
    text-align: center;
  }

  .services_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .services_available {
    min-width: 325px;
  }

  .service_item {
    font-size: 1.25rem;
  }
}
